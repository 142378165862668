<template>
	<div><img @click="toPoolAccount" style="width: 100%;" src="https://images.10000.work/zhiying.jpg" alt="" /></div>
</template>

<script>
export default {
	name: 'serial',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			imgVisible: false,
			role: ''
		};
	},
	created() {},
	methods: {
		toPoolAccount() {
			this.role = localStorage.getItem('ms_role');
			if (this.role == 'normal') {
				this.$message.error('请联系管理员开通寻迹通账号');
			} else {
				this.$router.push('/poolAccount');
			}
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
